import PipIcon from "../icons/PipIcon";

interface ImageDataOverlayProps {
  title?: string;
  description: string;
  dataOne?: string;
  dataTwo?: string;
  dataThree?: string;
  className?: string;
}

const ImageDataOverlay = ({
  title,
  description,
  dataOne,
  dataTwo,
  dataThree,
  className,
}: ImageDataOverlayProps) => {
  return (
    <div
      style={{ zIndex: 20, bottom: 10 }}
      className={`text-white flex-col items-start justify-start ${className}`}
      id="image-data-overlay"
    >
      <h1
        style={{
          marginBottom: 6,
          lineHeight: 1,
          textAlign: "left",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {title}
      </h1>
      <div
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {description}
      </div>
      {dataOne && (
        <div className="flex-row items-center justify-start mt-0">
          <div className="color-fade" style={{ textTransform: "capitalize" }}>
            {dataOne}
          </div>
          {dataTwo && (
            <>
              <PipIcon className="px-0" />
              <div
                className="color-fade"
                style={{ textTransform: "capitalize" }}
              >
                {dataTwo}
              </div>
              {dataThree && (
                <>
                  <PipIcon className="px-0" />
                  <div
                    className="color-fade"
                    style={{ textTransform: "capitalize" }}
                  >
                    {dataThree}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageDataOverlay;
