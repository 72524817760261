import { Link } from "react-router-dom";
import imageLoader from "../assets/image-loader.gif";

interface BrowseCardProps {
  img: string;
  alt: string;
  title: string;
  description: string;
  link: string;
}

const BrowseCard = ({
  img,
  alt,
  title,
  description,
  link,
}: BrowseCardProps) => {
  return (
    <Link className="mt-4" to={link}>
      <div
        className="border-sm-fade rounded-sm "
        style={{ minHeight: 235, display: "grid", placeItems: "center" }}
      >
        <img
          loading="lazy"
          src={img || imageLoader}
          alt={alt}
          className="rounded-sm"
          style={{
            width: img ? "100%" : 60,
            height: img ? "100%" : 60,
            overflow: "hidden",
          }}
        />
      </div>
      <p
        className="color-white"
        style={{
          marginTop: 10,
          marginBottom: 1,
          fontSize: 14,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {title}
      </p>
      <p
        className="color-white "
        style={{
          marginTop: 1,
          fontSize: 14,
          opacity: 0.6,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {description}
      </p>
    </Link>
  );
};

export default BrowseCard;
